


























import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component, Prop } from 'vue-property-decorator';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: { IllustrationComponent },
})
export default class FaqHeaderComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: '' })
  private readonly title!: string;

  @Prop({ required: false, default: '' })
  private readonly subtitle!: string;

  @Prop({ required: false, default: '' })
  private readonly subject!: string;

  private IllustrationType = IllustrationType;

  created(): void {
    this.setDataConfig();
  }
}
